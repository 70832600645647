import { getRewrittenUrl } from '../../analytics-library/entry';
import { getCustomTrackingId } from '../../analytics-library/getCustomTrackingId';
import type { Site } from '../../types/Site';

import getUrlParameters from './getUrlParameters';
import track from './tracking';

interface PostProcessElementsOptions {
  el: HTMLAnchorElement;
  index: number;
  site: Site | Record<string, never>;
  territory: string;
  articleName: string;
  articleUrl: string;
  totalLinks: number;
  widgets: HTMLElement[];
  articleId: string | null;
}

export default async ({
  el,
  index,
  site = {},
  territory = 'GB',
  articleName,
  articleUrl,
  totalLinks,
  widgets,
  articleId,
}: PostProcessElementsOptions): Promise<void> => {
  // Record that the link has been parsed and rewrite the custom tracking
  // using the elements data attributes
  el.classList.add('hawk-link-parsed');

  // Also check that the link hasn't already had it's tracking rewritten
  if (
    (el.getAttribute('data-hl-processed') ||
      (el.href && el.href.indexOf('-custom-tracking') >= 0)) &&
    !el.getAttribute('data-custom-tracking-id')
  ) {
    const { customTrackingId: forcedCustomTrackingId } = getUrlParameters(['customTrackingId']);
    const customTrackingId = await getCustomTrackingId({
      params: {
        pageUrl: document.location.href,
        site,
        text: el.textContent,
        href: el.href,
        territory,
        ...el.dataset,
      },
      customTrackingId: forcedCustomTrackingId,
    });
    // Check there is a href to avoid breaking links without hrefs - HAWK-495
    if (el.href) {
      el.href = getRewrittenUrl(
        el.href,
        site.analyticsName,
        territory,
        customTrackingId,
        articleUrl,
        articleName,
      );
    }
    el.setAttribute('data-custom-tracking-id', customTrackingId);

    // Widget links are (should be) handled by widget tracking - don't track here
    if (!widgets.some((widget) => widget.contains(el))) {
      track(el, index, totalLinks, site, articleId, articleUrl);
    }
  }
};
